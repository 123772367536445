import React from "react";
import "./Mission.css";
const Mission = () => {
  return (
    <>
      <section className="about-section">
        <div className="about-title">
          <h4 data-aos="fade-down" data-aos-duration="2000">Mission</h4>
        </div>

        <div className="container">
          <div className="about-main" data-aos="fade-up"
                  data-aos-duration="3000">
            <p>
              We want to create a financial system where all people have equal
              rights and where spending, receiving and exchanging money is easy
              and open to all. May this system quickly spread throughout the
              world. Where you can make your own decisions and have complete
              control over your finances.
            </p>
            <p>
              Accelerate the emergence of an open, democratic financial system
              for humanity.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Mission;
