import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../Contexts/AuthContext/AuthProvider';

const UserPrivateRoute = ({children}) => {
    const { authUser } = useContext(AuthContext);
    const location = useLocation();
    if(authUser?._id === undefined ){
        return <Navigate to='/login' sate={{from:location}} replace ></Navigate>
        
    }else{
        if(authUser?._id ){
            return children;
        }else{
            <Navigate to='*' sate={{from:location}} replace></Navigate>
        }
    }

  
    
}

export default UserPrivateRoute;