import React from "react";
import "./Security.css";
import image from "./Security.png";
const Security = () => {
  return (
    <>
      <section className="about-section">
        <div className="about-title">
          <h4>Security</h4>
        </div>

        <div className="container">
          <div className="security-content">
            <div className="row">
              <div className="col-6">
                <h6>Secure and Private</h6>
                <p>
                  We do not collect personal information from users. Most
                  advanced encryption methods require documents for account
                  authentication. This makes the account strong and secure. and
                  6-digit multiple passcodes, ensuring the security of your
                  assets
                </p>
              </div>
              <div className="col-6">
                <img src={image} alt="" srcSet=""  data-aos="zoom-in-up" data-aos-duration="2000"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Security;
