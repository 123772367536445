import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../Contexts/AuthContext/AuthProvider';

const LoginRoute = ({children}) => {
    const { authUser } = useContext(AuthContext);
    const location = useLocation();

    if(authUser?._id === undefined){
        return children;
        
    }
    else if(authUser?._id ){
        return <Navigate to='/user/trade' state={{from:location}} replace ></Navigate>

    }
 
   

  
   
}

export default LoginRoute;