import React, { useContext, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";
const SignUpForm = () => {
  const { LoginWithEmail } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const userFrom = location.state?.from?.pathname || "/user/markets";

  const [userData, setUserData] = useState({});
  const [userEmailError, setUserEmailError] = useState({});
  const refSubmitEmail = useRef();

  const handleRegister = (event) => {
    event.preventDefault();
    refSubmitEmail.current.setAttribute("disabled", true);
    const formData = new FormData(event.currentTarget);
    const InputData = Object.fromEntries(formData);
    fetch(`${process.env.REACT_APP_API}/api/user/auth/signup/email`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(InputData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === false) {
          if(data?.errors){
            setUserEmailError(data?.errors);
          }else{
            setUserEmailError(data);
          }
          refSubmitEmail.current.removeAttribute("disabled");
        } else {
          setUserData(data);
        }
      })
      .catch((error) => console.log(error));
  };

  // Verify Code Section

  const [userVerifyEmail, setUserVerifyEmail] = useState({});
  const [userCodeError, setUserCodeError] = useState({});
  const refSubmitCode = useRef();

  const handleRegisterVerifyCode = (event) => {
    event.preventDefault();
    refSubmitCode.current.setAttribute("disabled", true);
    const formData = new FormData(event.currentTarget);
    const InputData = Object.fromEntries(formData);
    const data = { ...InputData, email: userData?.data?.email };
    setResend({});
    fetch(`${process.env.REACT_APP_API}/api/user/auth/signup/email/verify`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: `Beare ${userData.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === false) {
          if (data?.errors) {
            setUserCodeError(data?.errors);
          } else {
            setUserCodeError(data);
          }
          refSubmitCode.current.removeAttribute("disabled");
        } else {
          setUserVerifyEmail(data);
        }
      })
      .catch((error) => console.log(error));
  };
  // resend
  const refSubmitCodeResend = useRef();
  const [resend, setResend] = useState({});
  const handleResendCode = (event) => {
    event.preventDefault();
    refSubmitCodeResend.current.setAttribute("disabled", true);
    setUserCodeError({});
    setResend({});
    const formData = new FormData(event.currentTarget);
    const InputData = Object.fromEntries(formData);
    const data = { ...InputData, email: userData.data.email };
    fetch(`${process.env.REACT_APP_API}/api/user/auth/signup/email`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === false) {
        } else {
          refSubmitCodeResend.current.removeAttribute("disabled");
          setResend(data);
        }
      })
      .catch((error) => console.log(error));
  };

  const refSubmitPassword = useRef();
  const [userPasswordError, setUserPasswordError] = useState({});


  const handleRegisterPassword = (event) => {
    event.preventDefault();
    refSubmitPassword.current.setAttribute("disabled", true);
    const formData = new FormData(event.currentTarget);
    const InputData = Object.fromEntries(formData);
    const data = { ...InputData, email: userVerifyEmail?.data?.email };
    setUserPasswordError({});
    fetch(`${process.env.REACT_APP_API}/api/user/auth/signup`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success === false) {
          setUserPasswordError(data?.errors);
          refSubmitPassword.current.removeAttribute("disabled");
        } else {
          const user = data;
          localStorage.setItem("robicon_id", JSON.stringify(user.data));
          const expires = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toUTCString();
          document.cookie = `robicon_token=OiJpcWJhbDExMSIsInVzZXJfaWQiOiI2M2VhNmE3MmJ1c2VyX25hbWMzODM5NX0VzZXJfaWQiOiI2M2InVzZXJfaWQiOiI2M2VhNmE3MmU4N2U5ZWJkNGM; expires=${expires}; path=/`;
          event.target.reset();
          if (user.data) {
            LoginWithEmail(user.data);
            navigate(userFrom, { replace: true });
          }
        }
      })
      .catch((error) => console.log(error));
  };



  const [passwordType, setPasswordType] = useState("password");
  const eyeActiveRef = useRef();

  const showPassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      eyeActiveRef.current.classList.add("active");
    } else {
      setPasswordType("password");
      eyeActiveRef.current.classList.remove("active");
    }
  };

  const [CPasswordType, setCPasswordType] = useState("password");
  const eyeCpasswordActiveRef = useRef();

  const showCPassword = () => {
    if (CPasswordType === "password") {
      setCPasswordType("text");
      eyeCpasswordActiveRef.current.classList.add("active");
    } else {
      setCPasswordType("password");
      eyeCpasswordActiveRef.current.classList.remove("active");
    }
  };

  return (
    <>
      <section className="body-section">
        <section className="login-section">
          <div className="login-area">
            <div className="login-card">
              <div className="login-img">
                <Link to="/">
                  {/* <LazyLoadImage alt="img" effect="blur" src={logo} /> */}
                </Link>
              </div>

              <div className="login-form-area">
                {!userData?.success && (
                  <form onSubmit={handleRegister}>
                    <div className="input-group">
                      <label htmlFor="email">email</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email address"
                      />
                      <span className="text-danger text-start ms-2 pt-1">
                        {userEmailError?.email && userEmailError?.email?.msg}
                      </span>
                      <span className="text-danger text-start ms-2 pt-1">
                        {userEmailError?.message && userEmailError?.message}
                      </span>
                    </div>

                    <div className="submit-btn-area">
                      <button
                        type="submit"
                        className="button-1 submit-btn"
                        ref={refSubmitEmail}
                      >
                        Signup
                      </button>
                      <Link to="/login" className="button-1 submit-btn">
                        Login
                      </Link>
                    </div>
                  </form>
                )}
                {userData?.success && !userVerifyEmail.success && (
                  <>
                    <form onSubmit={handleRegisterVerifyCode}>
                      <div className="pb-4 text-center text-primary">
                        <span>{resend?.message && resend?.message}</span>
                      </div>
                      <div className="input-group">
                        <label htmlFor="email">Verify code</label>
                        <input
                          type="text"
                          id="code"
                          name="code"
                          placeholder="Enter your code"
                        />
                        <span className="text-danger text-start ms-2 pt-1">
                          {userCodeError?.code && userCodeError?.code?.msg}
                        </span>
                        <span className="text-danger text-start ms-2 pt-1">
                          {userCodeError?.message && userCodeError?.message}
                        </span>
                      </div>

                      <div className="submit-btn-area">
                        <button
                          type="submit"
                          ref={refSubmitCode}
                          className="button-1 submit-btn"
                        >
                          Verify
                        </button>
                      </div>
                    </form>
                    <form onClick={handleResendCode}>
                      <div className="submit-btn-area">
                        <button
                          type="submit"
                          ref={refSubmitCodeResend}
                          className="button-1 submit-btn"
                        >
                          Resend code
                        </button>
                      </div>
                    </form>
                  </>
                )}
                {userVerifyEmail.success && (
                  <>
                    <form onSubmit={handleRegisterPassword}>
                      <div className="input-group">
                        <label htmlFor="code">First Name</label>
                        <input
                          type="text"
                          id="fname"
                          name="fname"
                          placeholder="Enter your first name"
                        />
                        <span className="text-danger text-start ms-2 pt-1">
                          {userPasswordError?.fname &&
                            userPasswordError?.fname?.msg}
                        </span>
                      </div>
                      <div className="input-group">
                        <label htmlFor="code">Last Name</label>
                        <input
                          type="text"
                          id="lname"
                          name="lname"
                          placeholder="Enter your last name"
                        />
                            <span className="text-danger text-start ms-2 pt-1">
                          {userPasswordError?.lname &&
                            userPasswordError?.lname?.msg}
                        </span>
                      </div>
                      <div className="input-group toggle-eye">
                        <label htmlFor="code">Password</label>
                        <input
                          type={passwordType}
                          id="password"
                          name="password"
                          placeholder="Enter your password"
                        />
                            <span className="text-danger text-start ms-2 pt-1">
                          {userPasswordError?.password &&
                            userPasswordError?.password?.msg}
                        </span>
                        {/* <button type="button" onClick={showPassword}  ref={eyeActiveRef}>
                      <i className="fa fa-eye"></i>
                    </button> */}
                      </div>
                      <div className="input-group toggle-eye ">
                        <label htmlFor="code">Confirm Password</label>
                        <input
                          type={CPasswordType}
                          id="cpassword"
                          name="cpassword"
                          placeholder="Enter your confirm password"
                        />
                            <span className="text-danger text-start ms-2 pt-1">
                          {userPasswordError?.cpassword &&
                            userPasswordError?.cpassword?.msg}
                        </span>
                        {/* <button type="button" onClick={showCPassword}  ref={eyeCpasswordActiveRef}>
                      <i className="fa fa-eye"></i>
                    </button> */}
                      </div>

                      <div className="submit-btn-area">
                        <button
                          type="submit"
                          ref={refSubmitPassword}
                          className="button-1 submit-btn"
                        >
                          Confirm
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* <Link to="/" className="home-btn">
            Home
          </Link> */}
        </section>
      </section>
    </>
  );
};

export default SignUpForm;
