import React from "react";
import "./Benefits.css";

const Benefits = () => {
  return (
    <>
      <section className=" benefits-section about-section">
        <div className="about-title">
          <h4  data-aos="fade-down"  data-aos-duration="2000">What are the benefits for us here?</h4>
        </div>

        <div className="container">
          <div className="benefits-content-area">
            <ul>
              <li data-aos="fade-up"
                  data-aos-duration="3000">
                Protection of digital assets and privacy of information. Crypto
                transactions are protected by effective encryption methods.
              </li>
              <li data-aos="fade-up"
                  data-aos-duration="3000">
                User interface. Crypto wallet interfaces provide design elements
                and all of the capabilities required for easy application use.
              </li>
              <li data-aos="fade-up"
                  data-aos-duration="3000">
                Currency of different countries. Using our platform will save
                you from downloading separate applications for different
                cryptocurrencies. You can conveniently manage various assets in
                one place.
              </li>
              <li data-aos="fade-up"
                  data-aos-duration="3000">
                Express transactions. Users can go directly to the section to
                send or receive funds quickly.
              </li>
              <li data-aos="fade-up"
                  data-aos-duration="3000">
                Provides the ability to display cryptocurrency balances in a
                convenient currency. The functionality of the application allows
                users to see the current value of the cryptocurrency in a
                familiar or more user-friendly currency unit (UAH, USD, EUR and
                GBP). This helps to better assess the value of the portfolio and
                understand its dynamics more easily.
              </li>
              <li data-aos="fade-up"
                  data-aos-duration="3000">
                Rubicon Liberty Group is reliable and user-friendly, with its
                easy-to-use interface and unique functionality, Rubicon Liberty
                Group Trading platform provides a convenient solution for
                working with different types of cryptocurrencies and offers a
                wide choice for your investments and transactions.
              </li>
            </ul>
          </div>
          <div className="benefits-gold-investment-services-area">
            <div className="benefits-gold-investment-services">
              <h5 data-aos="fade-up"
                  data-aos-duration="3000">Gold Investment Services</h5>
              <p data-aos="fade-up"
                  data-aos-duration="3000">
                We provide access to a variety of gold investments, including
                physical gold, gold ETFs and gold mining stocks. This allows you
                to diversify your portfolio and choose the type of investment
                that best suits your financial goals.
              </p>
              <div className="benefits-gold-investment-services-list custom-lists ">
                <ol>
                  <li data-aos="fade-up"
                  data-aos-duration="3000">
                    Expert Market Analysis:
                    <p>
                      Our team of seasoned market analysts constantly monitors
                      the gold market, providing you with up-to-date information
                      and insights. This ensures that you can make informed
                      decisions based on the latest market trends and forecasts.
                    </p>
                  </li>
                  <li data-aos="fade-up"
                  data-aos-duration="3000">
                    Extensive support:
                    <p>
                      Our dedicated customer support team is always available to
                      help you with any queries or concerns regarding your gold
                      investment. We also offer educational resources including
                      tutorials and webinars to help you understand the
                      intricacies of gold investing.
                    </p>
                  </li>
                  <li data-aos="fade-up"
                  data-aos-duration="3000">
                    Flexible Investment Plan:
                    <p>
                      Choose Rubicon Liberty Group Limited for a safe, informed,
                      and profitable gold investment experience and join our
                      growing community of satisfied investors.
                    </p>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Benefits;
