import React from "react";
import "./Jobs.css";
import image from "./image.png";
import image3 from "./jobs.jpg";
import image4 from "./image3.svg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
const Jobs = () => {
  return ( 
    <>
      <section className="job-section">
        <div className="contact-us-section">
          <div className="contact-us">
          <div className="container">
          <div className="jobs-header-area">
              <div className="jobs-header">
                <span data-aos="fade-down" data-aos-duration="2000">
                  Join the Rubicoin Liberty Limited team!
                </span>
                <h3 data-aos="fade-down" data-aos-duration="2000">
                  We're bringing decentralized finance to the people. If you
                  want to join an engaging, remote-first, and passionate team at
                  the forefront of DeFi, you've come to the perfect spot.
                </h3>
                <p data-aos="fade-down" data-aos-duration="2000">
                  Work with products owned by Rubicoin Liberty Limited. Help us
                  empower everyday people to take control of their wealth.
                </p>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div className="x-why-work">
         <div className="container">
         <div className="row x__width x-why-work__section1">
            <div className="col-12 col-md-5">
              <h2 className="x-h2">
                <span data-aos="fade-up" data-aos-duration="3000">
                  Why work with the Rubicoin Liberty Limited team?
                </span>
              </h2>
              <p
                className="x-why-work__txt"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                Rubicoin Liberty Limited empowers people every day to escape the
                traditional financial system.
              </p>
              <p className="x-p" data-aos="fade-up" data-aos-duration="3000">
                Ready to take your place in building the future of digital
                assets? Are you passionate about blockchain and the massive
                changes it is creating around the world? Do you store some of
                your assets in digital assets? Do you believe that Bitcoin,
                Ethereum and other cryptocurrencies are starting to facilitate a
                great wealth transfer?
              </p>
            </div>
            <div className="col-12 col-md-7">
              <img
                className="x-why-work__img"
                src={image}
                alt="Why work at Exodus?"
                data-aos="zoom-in-up"
                data-aos-duration="2000"
              />
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div
              className="x-why-work__features x__width"
              style={{ display: "block" }}
            >
              <div
                className="row carousel-inner"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div
                  className="col-12 col-md-3 x-why-work__features-item"
                  style={{ minHeight: "254px" }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <linearGradient
                        x1="100%"
                        y1="100%"
                        x2="0%"
                        y2="0%"
                        id="x-why-work__feature1"
                      >
                        <stop stopColor="#556FF2" offset="0%"></stop>
                        <stop stopColor="#00BFFF" offset="100%"></stop>
                      </linearGradient>
                    </defs>
                    <g fill="none" fillRule="evenodd">
                      <circle cx="32" cy="32" r="32" fill="none"></circle>
                      <circle
                        cx="32"
                        cy="32"
                        r="32"
                        fill="url(#x-why-work__feature1)"
                      ></circle>
                      <g fill="#FFF" fillRule="nonzero">
                        <path d="M20.8 26.488v-.26a4.71 4.71 0 0 1 1.082-2.826 4.096 4.096 0 0 1 1.444-1.099l2.963-1.158c.392-.146.683-.205.8-.066a.155.155 0 0 1 .01.015l.753 1.642c1.442.145 2.546 1.468 2.548 3.053v.7a.358.358 0 0 1 .249-.089c.36.032.592.741.518 1.584-.072.83-.413 1.476-.767 1.467v.398c0 1.915-1.991 4.841-4.8 4.841-2.81 0-4.8-2.925-4.8-4.84v-.399c-.354.01-.696-.637-.768-1.467-.074-.843.159-1.552.519-1.584a.359.359 0 0 1 .249.088zm8.484 9.146l4.544 1.648a2.18 2.18 0 0 1 1.372 2.033V43H16v-3.685a2.178 2.178 0 0 1 1.372-2.033l4.595-1.67c.774.934 1.956 1.4 3.633 1.43 1.73.03 2.92-.448 3.684-1.408z"></path>
                        <path
                          d="M35.657 27.407v-.217a3.859 3.859 0 0 1 .927-2.355c.34-.39.759-.706 1.238-.916l2.54-.965c.336-.122.585-.17.685-.055a.13.13 0 0 1 .01.013l.645 1.368c1.236.121 2.182 1.223 2.184 2.544v.583a.312.312 0 0 1 .213-.073c.308.026.508.617.445 1.32-.063.69-.355 1.23-.658 1.222v.332c0 1.596-1.707 4.034-4.115 4.034-2.408 0-4.114-2.438-4.114-4.034v-.332c-.303.007-.596-.531-.658-1.222-.063-.703.136-1.294.445-1.32a.313.313 0 0 1 .213.073zm.914 13.76v-1.852a3.553 3.553 0 0 0-2.237-3.311l-.242-.088 2.566-.906c.663.778 1.676 1.167 3.113 1.191 1.483.025 2.504-.372 3.158-1.173l3.895 1.374A1.817 1.817 0 0 1 48 38.096v3.07H36.571z"
                          opacity=".4"
                        ></path>
                      </g>
                    </g>
                  </svg>
                  <h3>Open Culture</h3>
                  <p className="x-p">
                    Ability to manage your own account, no company secrets.
                    Trust and transparency carry on from the blockchain to our
                    team.
                  </p>
                </div>
                <div
                  className="col-12 col-md-3 x-why-work__features-item"
                  style={{ minHeight: "254px" }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <linearGradient
                        x1="100%"
                        y1="100%"
                        x2="0%"
                        y2="0%"
                        id="x-why-work__feature2"
                      >
                        <stop stopColor="#420BE6" offset="0%"></stop>
                        <stop stopColor="#A16BB3" offset="100%"></stop>
                      </linearGradient>
                    </defs>
                    <g fill="none" fillRule="evenodd">
                      <circle cx="32" cy="32" r="32" fill="none"></circle>
                      <circle
                        cx="32"
                        cy="32"
                        r="32"
                        fill="url(#x-why-work__feature2)"
                      ></circle>
                      <path
                        fill="#FFF"
                        opacity=".3"
                        d="M22.467 37h19.066L45 45H19z"
                      ></path>
                      <path
                        d="M32 20c-3.933 0-8 3.048-8 8.129 0 4.81 7.2 12.33 7.533 12.668A.6.6 0 0 0 32 41a.6.6 0 0 0 .467-.203C32.8 40.458 40 33.007 40 28.129 40 23.049 35.933 20 32 20zm0 11.308c-1.76 0-3.2-1.454-3.2-3.231s1.44-3.23 3.2-3.23c1.76 0 3.2 1.453 3.2 3.23s-1.44 3.23-3.2 3.23z"
                        fill="#FFF"
                        fillRule="nonzero"
                      ></path>
                    </g>
                  </svg>
                  <h3>100% Remote Positions</h3>
                  <p className="x-p">
                    All our positions are 100% remote Various careers - work
                    from anywhere in the world.
                  </p>
                </div>
                <div
                  className="col-12 col-md-3 x-why-work__features-item"
                  style={{ minHeight: "254px" }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <linearGradient
                        x1="100%"
                        y1="100%"
                        x2="0%"
                        y2="0%"
                        id="x-why-work__feature3"
                      >
                        <stop stopColor="#5C26FF" offset="0%"></stop>
                        <stop stopColor="#8C66FF" offset="100%"></stop>
                      </linearGradient>
                    </defs>
                    <g fill="none" fillRule="evenodd">
                      <circle cx="32" cy="32" r="32" fill="none"></circle>
                      <circle
                        cx="32"
                        cy="32"
                        r="32"
                        fill="url(#x-why-work__feature3)"
                      ></circle>
                      <g fill="#FFF">
                        <path
                          d="M22 39h7v2h6v-2h7V24H22v15zm-3 0V22a1 1 0 0 1 1-1h24a1 1 0 0 1 1 1v17h3v3a1 1 0 0 1-1 1H17a1 1 0 0 1-1-1v-3h3z"
                          fillRule="nonzero"
                        ></path>
                        <path opacity=".3" d="M22 24h20v3l-20 9z"></path>
                      </g>
                    </g>
                  </svg>
                  <h3> Software</h3>
                  <p className="x-p">
                    We give priority to our employees in their choice. on each
                    client so that they can manage themselves. We pay special
                    attention to that.
                  </p>
                </div>
                <div
                  className="col-12 col-md-3 x-why-work__features-item"
                  style={{ minHeight: "254px" }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <linearGradient
                        x1="100%"
                        y1="100%"
                        x2="0%"
                        y2="0%"
                        id="x-why-work__feature4"
                      >
                        <stop stopColor="#FF9F19" offset="0%"></stop>
                        <stop stopColor="#FC3" offset="100%"></stop>
                      </linearGradient>
                    </defs>
                    <g fill="none" fillRule="evenodd">
                      <circle cx="32" cy="32" r="32" fill="none"></circle>
                      <circle
                        cx="32"
                        cy="32"
                        r="32"
                        fill="url(#x-why-work__feature4)"
                      ></circle>
                      <path
                        d="M33.732 15.956l11.536 6.548A3.395 3.395 0 0 1 47 25.453v13.094c0 1.217-.66 2.341-1.732 2.95l-11.536 6.547a3.516 3.516 0 0 1-3.464 0l-11.536-6.548A3.395 3.395 0 0 1 17 38.547V25.453c0-1.217.66-2.341 1.732-2.95l11.536-6.547a3.516 3.516 0 0 1 3.464 0z"
                        fill="#FFF"
                        opacity=".3"
                      ></path>
                      <path
                        d="M35.45 37.749c-1.078.37-2.083.217-2.53.155-.22-.03-.592-.099-.99-.182l-.635 2.371-1.514-.405.625-2.333-1.154-.31-.625 2.334-1.514-.406.625-2.333-2.979-.798.781-1.67.906.243c.304.081.439.088.597.007.158-.082.234-.252.273-.395l1.618-6.04c.101-.378.097-.489-.032-.732-.128-.243-.546-.382-.785-.446l-.875-.235.406-1.515 2.98.798.626-2.34 1.514.407-.627 2.339 1.154.309.627-2.34 1.514.406-.634 2.368c1.11.37 1.895.802 2.489 1.521.767.93.403 2.09.307 2.363-.096.274-.368.779-.716 1.058-.347.28-1.063.461-1.063.461s.695.347 1.112.86c.415.514.62 1.278.34 2.24-.281.964-.744 1.87-1.821 2.24zm-3.182-5.107c-.738-.249-1.713-.459-1.713-.459l-.838 3.13s.999.29 1.684.423c.685.132 1.422.154 1.818.063.396-.092.89-.266 1.082-.982.192-.716-.223-1.149-.47-1.361-.245-.213-.826-.565-1.563-.814zm-.064-1.574c.558.127 1.178.162 1.662.05.485-.11.82-.4.95-.937.128-.536-.12-1.084-.693-1.435s-.987-.477-1.46-.61c-.472-.134-.964-.223-.964-.223l-.754 2.817s.702.21 1.26.338z"
                        fill="#FFF"
                        fillRule="nonzero"
                      ></path>
                    </g>
                  </svg>
                  <h3>Withdrawal</h3>
                  <p className="x-p">
                    After a client makes a withdrawal request to Rubicon Liberty
                    ltd. It is transferred to my clients wallet within 24 hours.
                  </p>
                </div>
              </div>
            </div>
          </div>
         </div>
        </div>
        <div className="container">
          <div className="sophisticated-institutions">
            <div className="row">
              <div className="col-6">
                <img
                  src={image3}
                  alt=""
                  data-aos="zoom-in-up"
                  data-aos-duration="2000"
                />
              </div>
              <div className="col-6">
                <div
                  className="content-area"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <h4>
                    Powering the world's largest and most sophisticated
                    institutions
                  </h4>
                  <p>
                    Investors turn to Rubicon Liberty Group Ltd. for
                    leading-edge solutions to capitalize on opportunities in the
                    evolving digital asset landscape. We work with a variety of
                    institutional clients including investors, liquidity
                    providers, corporates, financial institutions, and private
                    clients to develop solutions that meet their diverse needs.
                  </p>
                  <div className="learn-More ">
                    <Link to="#"> Learn More </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="resources-section">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <img
                  src={image4}
                  alt=""
                  data-aos="zoom-in-up"
                  data-aos-duration="2000"
                />
              </div>
              <div className="col-6">
                <div
                  className="resources-content-area"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <div>
                    <h4>
                      Your resources, available at Rubicon Liberty Group Limited
                    </h4>
                    <p>
                      Use a single application to list on exchanges, custody and
                      all our trading interfaces
                    </p>
                    <div className="resources-content-dis-area">
                      <div className="resources-content-dis">
                        <FontAwesomeIcon icon={faCircleCheck} />
                        <span>Millions of active, trading users</span>
                      </div>
                      <div className="resources-content-dis">
                      <FontAwesomeIcon icon={faCircleCheck} />
                        <span>
                          Secure storage for your team and other token holders
                        </span>
                      </div>
                      <div className="resources-content-dis">
                      <FontAwesomeIcon icon={faCircleCheck} />
                        <span>Liquidity to ramp your network on/off</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Jobs;
