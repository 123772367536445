import React from "react";
import "./Footer.css";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQrcode,
  faSquarePollVertical,
  faArrowsTurnToDots,
  faNewspaper,
  faUser,

} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <>
      <section>
        <div className="menu-indicator-navigation">
          <ul>
            <li className="list-link">
              <NavLink to="/user/markets">
                <span className="icon">
                  <FontAwesomeIcon icon={faSquarePollVertical} />
                </span>
                <span className="text">Markets</span>
              </NavLink>
            </li>
            <li className="list-link">
              <NavLink to="/user/order">
                <span className="icon">
                  <FontAwesomeIcon icon={faQrcode} />
                </span>
                <span className="text">Orders</span>
              </NavLink>
            </li>
            <li className="list-link">
              <NavLink to="/user/trade">
                <span className="icon menu-indicator-trade">
                <FontAwesomeIcon icon={faArrowsTurnToDots} />
                </span>
              </NavLink>
            </li>

            <li className="list-link">
              <NavLink to="/user">
                <span className="icon">
                <FontAwesomeIcon icon={faNewspaper} />
                </span>
                <span className="text">News</span>
              </NavLink>
            </li>
            <li className="list-link">
              <NavLink to="/user">
                <span className="icon">
                <FontAwesomeIcon icon={faUser} />
                </span>
                <span className="text">Mine</span>
              </NavLink>
            </li>
            {/* <div className="indicator"></div> */}
          </ul>
        </div>
      </section>
    </>
  );
};

export default Footer;
