import React, { useContext, useEffect, useState } from "react";
import "./Header.css";
import logo2 from "./Logo.jpg";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSackDollar,
  faHandHoldingDollar,
  faOilWell,
  faCircleDollarToSlot,
  faBars,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import { faBitcoin } from "@fortawesome/free-brands-svg-icons";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";
const Header = ({ data }) => {
  const { authUser } = useContext(AuthContext);

  const {
    handleSubMenu,
    subMenuShowRef,
    subMenuActiveClass,
    subMenuCloseByMenu,
    handleMenuMobile,
    MenuMobileShowRef,
    subMenuMobileShowRef,
    handleSubMenuMobile,
    subMenuCloseByMenuMobile,
  } = data;

  // const menuActiveClass = useRef();

  const [navColor, setnavColor] = useState("transparent");
  const listenScrollEvent = () => {
    window.scrollY > 10
      ? setnavColor("rgba(11, 6, 25, 0.9)")
      : setnavColor("transparent");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (
    <>
      <header>
        <div className="Navbar___StyledPopover-sc-19dqj0b-20 cFGboa">
          <div
            className="Navbar__NavWrapper-sc-19dqj0b-1 iitcUi"
            style={{
              backgroundColor: navColor,
              transition: "all 0.5s",
            }}
          >
            <div className="Navbar___StyledDiv-sc-19dqj0b-14 kJIlUY">
              <Link to="/" onClick={subMenuCloseByMenu}>
                <span className="Navbar___StyledSpan2-sc-19dqj0b-15 gdxWox">
                  Rubicon
                </span>
                <img
                  src={logo2}
                  alt=""
                  className="Navbar___StyledImg-sc-19dqj0b-16 iitSla"
                />
              </Link>
            </div>
            <div className="Navbar___StyledDiv3-sc-19dqj0b-21 ighICX desktop-menu">
              <nav className="Navbar___StyledPopoverGroup-sc-19dqj0b-22 idhwIs">
                <Link
                  to="/about/us"
                  className="Navbar__NavbarLinkWrapper-sc-19dqj0b-2 bjtaVo"
                  onClick={subMenuCloseByMenu}
                >
                  About Us
                </Link>

                <div className="Navbar___StyledPopover2-sc-19dqj0b-23 jyIJUV">
                  <div className="group">
                    <div className="group">
                      <button
                        className="Navbar__CommunityPopoverButton-sc-19dqj0b-0 epJUan"
                        id="headlessui-popover-button-:R56l6:"
                        type="button"
                        aria-expanded="false"
                        onClick={handleSubMenu}
                      >
                        <span className="Navbar___StyledSpan-sc-19dqj0b-11 kZVkLy">
                          Products
                        </span>
                        <FontAwesomeIcon icon={faAngleDown} />
                      </button>
                    </div>
                  </div>
                  <div
                    className="Navbar__CommunityPanel-sc-19dqj0b-8 bPyWNO enterTo"
                    style={{ display: "none" }}
                    ref={subMenuShowRef}
                  >
                    <div
                      className="Navbar__CommunityBackdrop-sc-19dqj0b-5 hmyHJB"
                      ref={subMenuActiveClass}
                    >
                      <div className="Navbar__CommunityList-sc-19dqj0b-6 iDBTFF">
                        <div className="group">
                          <Link
                            to="/login"
                            className="Navbar__CommunityLinkWrapper-sc-19dqj0b-4 bxUEjX"
                            onClick={handleSubMenu}
                          >
                            <div className="Navbar__CommunityItem-sc-19dqj0b-7 fZyHgL">
                              <FontAwesomeIcon icon={faSackDollar} />
                            </div>
                            <div className="Navbar___StyledDiv2-sc-19dqj0b-17 hmtesw">
                              <p className="Navbar___StyledP-sc-19dqj0b-18 bDNURc">
                                Forex
                              </p>
                            </div>
                          </Link>
                        </div>
                        <div className="group">
                          <Link
                            to="/login"
                            className="Navbar__CommunityLinkWrapper-sc-19dqj0b-4 bxUEjX"
                            onClick={handleSubMenu}
                          >
                            <div className="Navbar__CommunityItem-sc-19dqj0b-7 fZyHgL">
                              <FontAwesomeIcon icon={faHandHoldingDollar} />
                            </div>
                            <div className="Navbar___StyledDiv2-sc-19dqj0b-17 hmtesw">
                              <p className="Navbar___StyledP-sc-19dqj0b-18 bDNURc">
                                Metal
                              </p>
                            </div>
                          </Link>
                        </div>
                        <div className="group">
                          <Link
                            to="/login"
                            className="Navbar__CommunityLinkWrapper-sc-19dqj0b-4 bxUEjX"
                            onClick={handleSubMenu}
                          >
                            <div className="Navbar__CommunityItem-sc-19dqj0b-7 fZyHgL">
                              <FontAwesomeIcon icon={faOilWell} />
                            </div>
                            <div className="Navbar___StyledDiv2-sc-19dqj0b-17 hmtesw">
                              <p className="Navbar___StyledP-sc-19dqj0b-18 bDNURc">
                                Oil
                              </p>
                            </div>
                          </Link>
                        </div>
                        <div className="group">
                          <Link
                            to="/login"
                            className="Navbar__CommunityLinkWrapper-sc-19dqj0b-4 bxUEjX"
                            onClick={handleSubMenu}
                          >
                            <div className="Navbar__CommunityItem-sc-19dqj0b-7 fZyHgL">
                              <FontAwesomeIcon icon={faCircleDollarToSlot} />
                            </div>
                            <div className="Navbar___StyledDiv2-sc-19dqj0b-17 hmtesw">
                              <p className="Navbar___StyledP-sc-19dqj0b-18 bDNURc">
                                CFD
                              </p>
                            </div>
                          </Link>
                        </div>
                        <div className="group">
                          <Link
                            to="/login"
                            className="Navbar__CommunityLinkWrapper-sc-19dqj0b-4 bxUEjX"
                            onClick={handleSubMenu}
                          >
                            <div className="Navbar__CommunityItem-sc-19dqj0b-7 fZyHgL">
                              <FontAwesomeIcon icon={faBitcoin} />
                              <i className="fa-brands fa-bitcoin"></i>
                            </div>
                            <div className="Navbar___StyledDiv2-sc-19dqj0b-17 hmtesw">
                              <p className="Navbar___StyledP-sc-19dqj0b-18 bDNURc">
                                Crypto
                              </p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Link
                  to="benefits"
                  className="Navbar__NavbarLinkWrapper-sc-19dqj0b-2 bjtaVo"
                  onClick={subMenuCloseByMenu}
                >
                  Benefits
                </Link>

                <Link
                  to="/jobs"
                  className="Navbar__NavbarLinkWrapper-sc-19dqj0b-2 bjtaVo"
                  onClick={subMenuCloseByMenu}
                >
                  Jobs
                </Link>
              </nav>
            </div>
            <div className="Navbar___StyledDiv4-sc-19dqj0b-26 jsPuOT">
              <div>
              {authUser?._id ? (
                <>
                  <NavLink
                    to="/user/markets"
                    className="Navbar___StyledA-sc-19dqj0b-13 iWTuSI"
                    onClick={subMenuCloseByMenu}
                  >
                    DashBoard
                  </NavLink>
                </>
              ) : (
                <>
                  <NavLink
                    to="/login"
                    className="Navbar___StyledA-sc-19dqj0b-13 iWTuSI"
                    onClick={subMenuCloseByMenu}
                  >
                    Sign Up/Log in
                  </NavLink>
                </>
              )}
              </div>
              <div className="mobile-header-menu">
                <FontAwesomeIcon icon={faBars} onClick={handleMenuMobile} />
              </div>
            </div>
            <nav
              className="Navbar___StyledPopoverGroup2-sc-19dqj0b-34 inXrJ"
              ref={MenuMobileShowRef}
              style={{ display: "none" }}
            >
                <Link
                to="/about/us"
                className="Navbar__NavbarLinkWrapperMobile-sc-19dqj0b-3 eTkSck"  onClick={subMenuCloseByMenuMobile}
              >
                About Us
              </Link>
              <div className="Navbar___StyledPopover5-sc-19dqj0b-35 dCckjJ">
                <div className="group">
                  <button
                    className="Navbar__CommunityPopoverButton-sc-19dqj0b-0 epJUan"
                    onClick={handleSubMenuMobile}
                  >
                    <span className="Navbar___StyledSpan-sc-19dqj0b-11 kZVkLy">
                      Products
                    </span>
                    <FontAwesomeIcon icon={faAngleDown} />
                  </button>
                </div>
                <div
                  className="Navbar__CommunityPanel-sc-19dqj0b-8 bPyWNO enterTo mobile-sub-menu"
                  style={{ display: "none" }}
                  ref={subMenuMobileShowRef}
                >
                  <div
                    className="Navbar__CommunityBackdrop-sc-19dqj0b-5 hmyHJB"
                    // ref={subMenuActiveClass}
                  >
                    <div className="Navbar__CommunityList-sc-19dqj0b-6 iDBTFF">
                      <div className="group">
                        <Link
                          to="/login"
                          className="Navbar__CommunityLinkWrapper-sc-19dqj0b-4 bxUEjX"
                          onClick={subMenuCloseByMenuMobile}
                        >
                          <div className="Navbar__CommunityItem-sc-19dqj0b-7 fZyHgL">
                            <FontAwesomeIcon icon={faSackDollar} />
                          </div>
                          <div className="Navbar___StyledDiv2-sc-19dqj0b-17 hmtesw">
                            <p className="Navbar___StyledP-sc-19dqj0b-18 bDNURc">
                              Forex
                            </p>
                          </div>
                        </Link>
                      </div>
                      <div className="group">
                        <Link
                          to="/login"
                          className="Navbar__CommunityLinkWrapper-sc-19dqj0b-4 bxUEjX"
                          onClick={subMenuCloseByMenuMobile}
                        >
                          <div className="Navbar__CommunityItem-sc-19dqj0b-7 fZyHgL">
                            <FontAwesomeIcon icon={faHandHoldingDollar} />
                          </div>
                          <div className="Navbar___StyledDiv2-sc-19dqj0b-17 hmtesw">
                            <p className="Navbar___StyledP-sc-19dqj0b-18 bDNURc">
                              Metal
                            </p>
                          </div>
                        </Link>
                      </div>
                      <div className="group">
                        <Link
                          to="/login"
                          className="Navbar__CommunityLinkWrapper-sc-19dqj0b-4 bxUEjX"
                          onClick={subMenuCloseByMenuMobile}
                        >
                          <div className="Navbar__CommunityItem-sc-19dqj0b-7 fZyHgL">
                            <FontAwesomeIcon icon={faOilWell} />
                          </div>
                          <div className="Navbar___StyledDiv2-sc-19dqj0b-17 hmtesw">
                            <p className="Navbar___StyledP-sc-19dqj0b-18 bDNURc">
                              Oil
                            </p>
                          </div>
                        </Link>
                      </div>
                      <div className="group">
                        <Link
                          to="/login"
                          className="Navbar__CommunityLinkWrapper-sc-19dqj0b-4 bxUEjX"
                          onClick={subMenuCloseByMenuMobile}
                        >
                          <div className="Navbar__CommunityItem-sc-19dqj0b-7 fZyHgL">
                            <FontAwesomeIcon icon={faCircleDollarToSlot} />
                          </div>
                          <div className="Navbar___StyledDiv2-sc-19dqj0b-17 hmtesw">
                            <p className="Navbar___StyledP-sc-19dqj0b-18 bDNURc">
                              CFD
                            </p>
                          </div>
                        </Link>
                      </div>
                      <div className="group">
                        <Link
                          to="/login"
                          className="Navbar__CommunityLinkWrapper-sc-19dqj0b-4 bxUEjX"
                          onClick={subMenuCloseByMenuMobile}
                        >
                          <div className="Navbar__CommunityItem-sc-19dqj0b-7 fZyHgL">
                            <FontAwesomeIcon icon={faBitcoin} />
                            <i className="fa-brands fa-bitcoin"></i>
                          </div>
                          <div className="Navbar___StyledDiv2-sc-19dqj0b-17 hmtesw">
                            <p className="Navbar___StyledP-sc-19dqj0b-18 bDNURc">
                              Crypto
                            </p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Link
                to="/benefits"
                className="Navbar__NavbarLinkWrapperMobile-sc-19dqj0b-3 eTkSck"  onClick={subMenuCloseByMenuMobile}
              >
                Benefits
              </Link>
              <Link
                to="/jobs"
                className="Navbar__NavbarLinkWrapperMobile-sc-19dqj0b-3 eTkSck"  onClick={subMenuCloseByMenuMobile}
              >
                Jobs
              </Link>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
