import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faSquareTwitter,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
  return (
    <>
      <footer className="hidden-in-bnc-app footer-wrapper dark-color common-widget-css_wrap theme-root">
        <div className="footer-content">
          <div className="footer-community">
            <h3 className="footer-navlist-title">Community</h3>
            <div className="footer-icongroup">
              <a href="" >
              <FontAwesomeIcon  icon={faLinkedin} />
              </a>
              <a href="">
              <FontAwesomeIcon  icon={faSquareTwitter} />
              </a>
              <a href="">
              <FontAwesomeIcon  icon={faTelegram} />
              </a>
            </div>
          </div>
          <div className="footer-navlist">
            <div className="footer-navlist-group">
              <div className="footer-navlist-sub">
                <h3 className="footer-navlist-title">
                  Forex
                  <div className="footer-navlist-expand-icon">
                    <svg
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      className="bn-svg"
                    >
                      <path
                        d="M13.5 3h-3v7.5H3v3h7.5V21h3v-7.5H21v-3h-7.5V3z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                </h3>
                <ul className="footer-navlist-item-list">
                  <li className="footer-navlist-item">
                    <Link
                      id="footer_menu_item_ba-About"
                      to="/forex"
                     
                      className="typography-Body3 color-textprimary"
                    >
                      Forex
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-navlist-group">
              <div className="footer-navlist-sub">
                <h3 className="footer-navlist-title">
                  Mission
                  <div className="footer-navlist-expand-icon">
                    <svg
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      className="bn-svg"
                    >
                      <path
                        d="M13.5 3h-3v7.5H3v3h7.5V21h3v-7.5H21v-3h-7.5V3z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                </h3>
                <ul className="footer-navlist-item-list">
                  <li className="footer-navlist-item">
                    <Link
                      id="footer_menu_item_ba-Exchange"
                      to="/mission"
                      className="typography-Body3 color-textprimary"
                    >
                      Mission
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-navlist-group">
              <div className="footer-navlist-sub">
                <h3 className="footer-navlist-title">
                  Security
                  <div className="footer-navlist-expand-icon">
                    <svg
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      className="bn-svg"
                    >
                      <path
                        d="M13.5 3h-3v7.5H3v3h7.5V21h3v-7.5H21v-3h-7.5V3z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                </h3>
                <ul className="footer-navlist-item-list">
                  <li className="footer-navlist-item">
                    <Link
                      id="footer_menu_item_ba-applyP2P"
                      to="/security"
                      className="typography-Body3 color-textprimary"
                    >
                      Security
                    </Link>
                  </li>
                  <li className="footer-navlist-item">
                    <Link
                      id="footer_menu_item_ba-applyP2P"
                      to="/privacy/policy"
                      className="typography-Body3 color-textprimary"
                    >
                     Privacy Policy
                    </Link>
                  </li>
                  <li className="footer-navlist-item">
                    <Link
                      id="footer_menu_item_ba-applyP2P"
                      to="/risk/disclosure"
                      className="typography-Body3 color-textprimary"
                    >
                    Risk Disclosure
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-navlist-group">
              <div className="footer-navlist-sub">
                <h3 className="footer-navlist-title">
                  Support
                  <div className="footer-navlist-expand-icon">
                    <svg
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      className="bn-svg"
                    >
                      <path
                        d="M13.5 3h-3v7.5H3v3h7.5V21h3v-7.5H21v-3h-7.5V3z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                </h3>
                <ul className="footer-navlist-item-list">
                  <li className="footer-navlist-item">
                    <Link
                      id="footer_menu_item_ba-Affiliate"
                      to="/contact/us"
                     
                      className="typography-Body3 color-textprimary"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-copyright">
          <div className="footer-copyright-line"></div>
          <div className="footer-copyright-text footer-copyright-enterprise text-center typography-Body3">
            <span>Rubicon</span>
            <span className="no-wrap">© 2024</span>
            <span
              className="footer-copyright-cookieprefrence"
              data-ot-trigger="true"
            >
              Cookie Preferences
            </span>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
