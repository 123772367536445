import React from 'react';
import "./TradePage.css";
import TradingViewWidget from '../../../TradingViewWidget/TradingViewWidget';

const TradePage = () => {
    return (
        <>
        <section className='trade-page-area'>
            <div className="container">
            <TradingViewWidget />
            </div>
        </section>
            
        </>
    );
};

export default TradePage;