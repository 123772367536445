import React from "react";
import "./Orders.css";

const Orders = () => {
  return (
    <>
      <section className="orders-area">
        <div className="container">
          <div className="orders-menu-area">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="active">Position holding</h5>
              <h5>Pending Orders</h5>
              <h5>History</h5>
            </div>
          </div>
          <div className="orders-profit-loss">
            <h5>Profit and Loss</h5>
            <h3>171606.1</h3>
          </div>
          <div className="user-orders-balance-area">
            <div className="d-flex justify-content-between align-items-center pb-2">
              <p>Balance</p>
              <span>1276450.94</span>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-2">
              <p>Current Margin</p>
              <span>34074.253</span>
            </div>
            <div className="d-flex justify-content-between align-items-center ">
              <p>Risk Rate</p>
              <span>4337.908455%</span>
            </div>
          </div>

          <div className="orders-history">
            <div className="orders-history-item">
              <div className="orders-history-title">
                <h5>XAUUSD</h5>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="orders-item-details">
                  <p>63714.53 &gt; 60444.11</p>
                  <span>Orders ID #170296</span>
                  <span>2024-07-15 21:04:43</span>
                </div>
                <div className="orders-item-up-value">
                  <h6>
                    <p>Sell</p> <span>0.5 Lots</span>
                  </h6>
                  <h5>169014.5</h5>
                </div>
              </div>
            </div>
            <div className="orders-history-item">
              <div className="orders-history-title">
                <h5>XAUUSD</h5>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="orders-item-details">
                  <p>63714.53 &gt; 60444.11</p>
                  <span>Orders ID #170296</span>
                  <span>2024-07-15 21:04:43</span>
                </div>
                <div className="orders-item-dwon-value">
                  <h6>
                    <p>Sell</p> <span>0.5 Lots</span>
                  </h6>
                  <h5>169014.5</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Orders;
