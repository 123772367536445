import React from "react";
import "./OurMission.css";
const OurMission = () => {
  return (
    <>
    <section className="our-mession-section">
      <div className="Mission___StyledDiv-sc-15y79n1-0 jvMkpH">
        <div className="Mission___StyledDiv2-sc-15y79n1-1 kBGSjg"></div>
        <div className="Mission___StyledDiv3-sc-15y79n1-2 cguWgh" data-aos="fade-down"  data-aos-duration="2000">
          <div className="Mission___StyledDiv4-sc-15y79n1-3 hLiJtT">
            <h2 className="Mission___StyledH-sc-15y79n1-7 jFkHiP">
              The Goal at Rubicon Liberty Group
            </h2>
            <p className="Mission___StyledP-sc-15y79n1-8 crCcLP">
            Remove the geek requirement to make digital currency easier for everyone, 
              <br />
              and prioritize the earning medium.
            </p>
            <span>We want to construct a financial system in which everyone can be an equal partner, with economic power distributed rather than concentrated in the hands of a few. It will promote financial independence and democratic decision-making while reducing economic inequality and poverty, resulting in equal possibilities for all.</span>
          </div>
        </div>
      </div>
      <div className="section" id="transf"></div>
      </section>
    </>
  );
};

export default OurMission;
