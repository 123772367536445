import React from "react";
import "./Market.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import Header from "../../Layouts/Header/Header";
const Market = () => {
  return (
    <>
      <Header></Header>
      <section className="markets-area">
        <div className="container">
          <div className="markets-menu">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="active">Forex</h5>
              <h5>Stocks</h5>
              <h5 >Commodities</h5>
              <h5>CFDs</h5>
              <h5>Crypto</h5>
            </div>
          </div>
          <div className="markets-items-area">
            <div className="markets-item">
              <div className="d-flex justify-content-between align-items-center">
                <div className="markets-item-name">
                  <LazyLoadImage
                    alt="logo img"
                    src="https://api.texttrademo.com/uploads/logo/XAUUSD.png"
                  />
                  <h6>XAUSUD</h6>
                </div>
                <div className="markets-value">
                  <p>1310.0</p>
                  <span>
                    +0.03% <FontAwesomeIcon icon={faCaretUp} />
                  </span>
                </div>
              </div>
            </div>
            <div className="markets-item">
              <div className="d-flex justify-content-between align-items-center">
                <div className="markets-item-name">
                  <LazyLoadImage
                    alt="logo img"
                    src="https://api.texttrademo.com/uploads/logo/XAUUSD.png"
                  />
                  <h6>XAUSUD</h6>
                </div>
                <div className="markets-value">
                  <p>1310.0</p>
                  <span>
                    +0.03% <FontAwesomeIcon icon={faCaretUp} />
                  </span>
                </div>
              </div>
            </div>
            <div className="markets-item">
              <div className="d-flex justify-content-between align-items-center">
                <div className="markets-item-name">
                  <LazyLoadImage
                    alt="logo img"
                    src="https://api.texttrademo.com/uploads/logo/XAUUSD.png"
                  />
                  <h6>XAUSUD</h6>
                </div>
                <div className="markets-value">
                  <p>1310.0</p>
                  <span>
                    +0.03% <FontAwesomeIcon icon={faCaretUp} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Market;
